export const environment = {
  production: false,
  oneSignalId: 'a23e0e4d-5d46-413b-b8c9-804be5ef3cc1',
  contentful: {
    spaceId: '8epo4uqdjsid',
    accessToken: 'oy9GoKVY5XiIAmP8_eDmClskXdYvDZq_SJ86vp27CXQ',
    environment: 'Development', // replace with your environment ID if needed - master is the default production environment in Contentful
  },
  auth0:{
    clientId: "WaZG8rEdbdKmTRT0ds6ocjblPke9R9I8",
    audience: "https://beta.vuturia.de/api"
  },
  vuturiaApi:{
    baseUrl: 'https://beta.vuturia.de'
    // baseUrl: 'https://player.vuturia.de'
    // baseUrl: 'http://localhost:5013'
    // baseUrl: 'https://qt62rf5d-5013.euw.devtunnels.ms'
  },
  applicationUrl: 'http://localhost:4200'
};