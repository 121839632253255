import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState } from "./authentication.reducer";

const selectAuthFeature = createFeatureSelector<AuthState>('authentication');

export const selectUser = createSelector(
    selectAuthFeature,
    (authState) => authState.account
);

export const selectSignedInProfile = createSelector(
    selectAuthFeature,
    (authState) => authState.signedInUserProfile
)

export const AuthSelectors = {
    selectUserProfileLoading: createSelector(
        selectAuthFeature,
        (authState) => authState.userProfileLoading
    )
}